import PropTypes from 'prop-types';
import {
    DEFAULT_JOB_PROPS,
    DEFAULT_JOB_PROPS2,
    DEFAULT_JOB_PROPS3,
} from './JobProps';
import { userImageProptype } from './userProps';

export const COMPANY_PROPTYPES = {
    /**
     *  Company display name
     */
    companyName: PropTypes.string,
    /**
     *  Company ID
     */
    companyId: PropTypes.number,
    /**
     *  company logo source (cloudinary ID without base URL) and alt text
     */
    logo: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    /**
     *  company url hash
     */
    companyUrl: PropTypes.string,
    /**
     * company's display URL
     */
    remoteUrl: PropTypes.string,
    /**
     *
     */
    careerUrl: PropTypes.string,
    /**
     *  company's user rating
     */
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     *  whether company is a sponsor
     */
    isSponsor: PropTypes.bool,
    /**
     *  number of reviews for company
     */
    reviewCount: PropTypes.number,
    /**
     *  whether user follows company - must be updated on load
     */
    isFollowed: PropTypes.bool,
    /**
     *  additional info if a Sponsor company
     */
    employerInfo: PropTypes.shape({
        /**
         *  banner image source (cloudinary ID without base URL) and alt text
         */
        banner: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        /**
         *  headquarters location
         */
        hq: PropTypes.shape({
            city: PropTypes.string,
            state: PropTypes.string,
            counrty: PropTypes.string,
        }),
        /**
         *  job listings count
         */
        jobCount: PropTypes.number,
        /**
         *  company size category
         */
        companySize: PropTypes.string,
    }),
    // GOOGLE SEO REVIEW MARKUP
};

export const DEFAULT_COMPANY_PROPS = {
    companyName: 'Company Name',
    companyId: 123,
    logo: {
        src: '/',
        alt: '',
    },
    companyUrl: '/',
    remoteUrl: '/',
    careerUrl: '/',
    rating: '',
    isSponsor: true,
    reviewCount: 123,
    isFollowed: false,
    employerInfo: {
        bannerSrc: '',
        hq: {
            city: '',
            state: '',
            counrty: '',
        },
        jobCount: 123,
        companySize: '100 - 100,000',
    },
    // GOOGLE SEO REVIEW MARKUP
};

export const NON_SPONSOR_DEFAULT_COMPANY_PROPS = {
    companyName: 'Company Name',
    companyId: 123,
    logo: {
        src: '/',
        alt: '',
    },
    companyUrl: '/',
    remoteUrl: '/',
    careerUrl: '/',
    rating: '',
    isSponsor: false,
    reviewCount: 123,
    isFollowed: false,
    employerInfo: {
        bannerSrc: '',
        hq: {
            city: '',
            state: '',
            counrty: '',
        },
        jobCount: 123,
        companySize: '100 - 100,000',
    },
    // GOOGLE SEO REVIEW MARKUP
};

export const COMPANY_BADGE_TYPES_PROP = PropTypes.oneOf([
    'B2016',
    'B2017',
    'B2018',
    'B2019',
    'B2020',
    'B2021',
    'B2022',
    'B2023',
    'Bgender-diversity-2018',
    'Btechnology-2018',
    'Bfinance-2018',
    'Bgender-diversity-2019',
    'Btechnology-2019',
    'Bfinance-2019',
    'Bconsulting-2019',
    'Bgender-diversity-2020',
    'Bgender-diversity-2021',
    'Bgender-diversity-2022',
    'Bgender-diversity-2023',
    'Btechnology-2020',
    'Btechnology-2021',
    'Btechnology-2022',
    'Btechnology-2023',
    'Bfinance-2020',
    'Bfinance-2021',
    'Bfinance-2022',
    'Bfinance-2023',
    'Bflexibility-2023',
    'Bmanager-2023',
    'Bconsulting-2020',
    'Bconsulting-2021',
    'Bconsulting-2022',
    'Bconsulting-2023',
    'Bgender-diversity-current',
    'Btechnology-current',
    'Bfinance-current',
    'Bconsulting-current',
    'current',
]).isRequired;

export const COMPANY_BADGE_IMAGE_SOURCES = {
    B2016: '/v1527612617/badge2016-v2_o90jin.png',
    B2017: '/v1527612617/badge2017-v2_vqiayr.png',
    B2018: '/v1527612617/badge2018-v2_x1xnka.png',
    B2019: '/v1558292830/badge2019_f5vcmc.png',
    B2020: '/v1602178692/Badge-BestCompany-2020-d_gpocht.png',
    B2021: '/v1631638436/Badge-BestCompany-2021-d_hhcgcf.png',
    B2022: 'v1663007326/Badge_-_Best_Company_2022_yvuvh5.png',
    B2023: '/v1/articles_upload/main/jnc5mcmupv9fyclt5jsi.webp',
    'Btechnology-2017': '/v1527612617/badge2017-v2m_vqiayr.png',
    'Bgender-diversity-2018': '/v1527612616/badge-ceo2018-v2_yapuw3.png',
    'Btechnology-2018': '/v1527612617/badge-technology2018-v2m_tu1obi.png',
    'Bfinance-2018': '/v1527612616/badge-financial2018-v2m_smle9y.png',
    'Bgender-diversity-2019': '/v1558292830/badge-ceo2019_ogng9p.png',
    'Btechnology-2019': '/v1558292830/badge-technology2019_jemrjq.png',
    'Bfinance-2019': '/v1558292830/badge-financial2019_bsicrd.png',
    'Bconsulting-2019': '/v1558292830/badge-consulting2019_h0mqgp.png',
    'Bgender-diversity-2020': '/v1602178692/Badge-CEO-2020-d_t7aenq.png',
    'Bgender-diversity-2021': '/v1631638436/Badge-CEO-2021-d_tg1gej.png',
    'Bgender-diversity-2022':
        '/v1663007326/Badge_-_Best_CEO-Gender_Diversity_2022_ztepeg.png',
    'Bgender-diversity-2023':
        '/v1/articles_upload/main/lmvmihjrkfuo9paswu6x.webp',
    'Btechnology-2020': '/v1602178692/Badge-Tech2020-d_skvk0d.png',
    'Btechnology-2021': '/v1631638436/Badge-Tech2021-d_evzgpw.png',
    'Btechnology-2022': '/v1663007326/Badge_-_Best_Technology_2022_eg5rnn.png',
    'Btechnology-2023': '/v1/articles_upload/main/jopgjtwphgsijsmf7syj.webp',
    'Bfinance-2020': '/v1602178692/Badge-financial-2020-d_zxk9il.png',
    'Bfinance-2021': '/v1631638437/Badge-financial-2021-d_bryias.png',
    'Bfinance-2022': '/v1663007326/Badge_-_Best_Financial_2022_t4mn6q.png',
    'Bfinance-2023': '/v1/articles_upload/main/rcixxaixgilm7e1gxnrg.webp',
    'Bflexibility-2023': '/v1/articles_upload/main/wtshqjwmj5eclcewinv3.webp',
    'Bmanager-2023': '/v1/articles_upload/main/zbosk0jqqdqgxqbkepyq.webp',
    'Bconsulting-2020': '/v1602178692/Badge-Consulting2020-d_fsjhtd.png',
    'Bconsulting-2021': '/v1631638436/Badge-Consulting2021-d_l7qunw.png',
    'Bconsulting-2022': 'v1663007326/Badge_-_Best_Consulting_2022_wbqnpo.png',
    'Bconsulting-2023': '/v1/articles_upload/main/aloz39cozppqhnsmoiw8.webp',
    'Bgender-diversity-current': '/v1530553845/badge-ceo-current-v2_mmsapr.png',
    'Btechnology-current':
        '/v1527612616/badge-technology-current-v2_hvuxez.png',
    'Bfinance-current': '/v1530553845/badge-financial-current-v2_pm6mcw.png',
    'Bconsulting-current': '/v1558292830/badge-consulting-current_i69tsn.png',
    current: '/v1527612616/badge_current-v2_xzohdi.png',
};

export const EMPLOYEE_PROPTYPES = {
    name: PropTypes.string,
    role: PropTypes.string,
    location: PropTypes.string,
    bio: PropTypes.string,
    image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
};

export const GENDER_DIVERSITY_PROPTYPES = {
    description: PropTypes.string,
    genderData: PropTypes.shape({
        womenStaff: PropTypes.number,
        womenSenior: PropTypes.number,
        womenDirector: PropTypes.number,
    }),
};

export const COMPANY_DEFAULT_PROPS = {
    companySlides: [
        {
            src: '/v1513324754/production/companies/27197/slide3.jpg',
            alt: '',
        },
        {
            src: '/v1513324757/production/companies/27307/slide2.jpg',
            alt: '',
        },
        {
            src: '/v1513324785/production/companies/27513/slide1.jpg',
            alt: '',
        },
    ],
    genderDiversity: {
        genderData: {
            womenStaff: 56,
            womenSenior: 56,
            womenDirector: 56,
        },
    },
    companyCulture:
        "Thomson Reuters provides professionals with the intelligence, technology and human expertise they need to find trusted answers. We enable professionals in the financial and risk, legal, tax and accounting, and media markets to make the decisions that matter most, all powered by the world's most trusted news organization.",
    sponsoredCompanyDescription:
        'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases. ' +
        'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
        'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
        'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.',
    companyBadges: [
        { type: 'B2016', url: '' },
        { type: 'B2017', url: '/' },
        { type: 'B2018', url: '/' },
    ],
    womenJobReviews: [
        {
            data: [
                {
                    title: 'Yes',
                    value: 50,
                },
                {
                    title: 'No',
                    value: 50,
                },
                {
                    title: 'Maybe',
                    value: 0,
                },
            ],
            chartDescription: 'blah blah blah',
        },
        {
            data: [
                {
                    title: 'Yes',
                    value: 30,
                },
                {
                    title: 'No',
                    value: 50,
                },
                {
                    title: 'Maybe',
                    value: 60,
                },
            ],
            chartDescription: 'blah blah blah',
        },
    ],
    companyReviews: [
        {
            user: {
                username: 'blahBLah',
                image: {
                    src: '/user/profile/cmruiux98xrwk7yecwxx',
                    alt: '',
                },
            },
            date: '1239-23-23',
            rating: '4.4',
            reviewDetails: [
                {
                    headline: 'Great',
                    response: 'df',
                    barChar: 4,
                    extraInfo: 'lakdjsf',
                    comment: 'laksdjlfa',
                },
            ],
            googleSeo: 'lkasdjfadf',
        },
        {
            user: {
                username: 'blahBLah',
                image: {
                    src: '/user/profile/cmruiux98xrwk7yecwxx',
                    alt: '',
                },
            },
            date: '1239-23-23',
            rating: '4.4',
            reviewDetails: [
                {
                    headline: 'Great',
                    response: 'df',
                    barChar: 4,
                    extraInfo: 'lakdjsf',
                    comment: 'laksdjlfa',
                },
            ],
            googleSeo: 'lkasdjfadf',
        },
    ],
    companyVideo: {
        videoSrc: 'https://www.youtube.com/embed/usHVxVjr8Q8',
        videoType: 'youtube',
    },
    employees: [
        {
            name: 'Bob saget',
            role: 'Professional World dominator',
            location: 'In a really far place, ny',
            bio:
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases. ' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.',
            image: {
                src: '/v1513324880/production/companies/45395/worker.jpg',
                alt: '/',
            },
        },
        {
            name: 'Twilight Sparkle',
            role: 'Professional World dominator',
            location: 'In a really far place, ny',
            bio:
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases. ' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.',
            image: {
                src: '/v1513324870/production/companies/42423/worker2.jpg',
                alt: '/',
            },
        },
        {
            name: 'Starlight Glimmer',
            role: 'Professional World dominator',
            location: 'In a really far place, ny',
            bio:
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases. ' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.' +
                'Women make up 43 percent of our employee population but only 30 percent of our senior leadership. I think we can do better. I believe diversity matters, and that our executive ranks need to better reflect our employee and customer bases.',
            image: {
                src: '/v1513324746/production/companies/21809/worker2.jpg',
                alt: '/',
            },
        },
    ],
    companyArticles: [
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
        {
            title:
                "I am really sleepy and hungry because I haven't slept in 5 days nor did I eat breakfast or lunch",
            image: '/production/tip/24/main_image',
            contentUrl: '/',
            description:
                "If you're a NY employee, be sure you know what rights you have!",
        },
    ],
    jobs: [DEFAULT_JOB_PROPS, DEFAULT_JOB_PROPS2, DEFAULT_JOB_PROPS3],
    crowdSourcedBenefitData: [
        { type: 'vacation', median: 3.5 },
        { type: 'paid_maternity', median: 8 },
        { type: 'unpaid_maternity', median: 6 },
        { type: 'paid_paternity', median: 6 },
    ],
    socialMediaAccounts: {
        twitter: 'IBM',
    },
    topics: [{}],
};

// videoSrc: "/v1543256703/employer/banner/kqm1hxxxp2pwti35t3jb.mp4",
// videoType: CLOUDINARY

/**
 * meta data for featured company's ads
 */
export const featureCompanyPropType = PropTypes.shape({
    /**
     * image for header banner
     */
    headerImage: userImageProptype.isRequired,
    /**
     * featured company meta data
     */
    company: PropTypes.shape({
        /**
         * image for company logo
         */
        logo: userImageProptype.isRequired,
        /**
         * id for company
         */
        id: PropTypes.number.isRequired,
    }),
    /**
     * title for the featured company ads
     */
    title: PropTypes.string.isRequired,
    /**
     * description for the featured company ads
     */
    description: PropTypes.string.isRequired,
    /**
     * url of the company's job listing page
     */
    url: PropTypes.string.isRequired,
    /**
     * text to be displayed on button that route to company's job listing page
     */
    buttonText: PropTypes.string.isRequired,
});
